export const conf = {
  "aws_appsync_graphqlEndpoint": "https://2gp3b52bfnbf5mm2c3w3h7pj3e.appsync-api.eu-west-1.amazonaws.com/graphql",
  "aws_appsync_region": "eu-west-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "Auth": {
    "region": "eu-west-1",
    "userPoolId": "eu-west-1_on3chAulZ",
    "userPoolWebClientId": "1gfr1tjgorfvmefu7itv5aajnj",
    "oauth": {
      "domain": "auth2.varaus.net",
      "scope": [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin"
      ],
      "redirectSignIn": "http://localhost:3000/",
      "redirectSignOut": "http://localhost:3000/",
      "responseType": "code"
    }
  }
}