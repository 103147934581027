/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Calendar = {
  __typename: "Calendar",
  slug: string,
  name: string,
  description?: string | null,
  owner: string,
  dayStart: number,
  dayEnd: number,
  reservationTypes?:  Array<ReservationType2 > | null,
  reservations?:  Array<Reservation > | null,
  members?:  Array<Membership > | null,
  myMembership?: Membership | null,
};

export type ReservationType2 = {
  __typename: "ReservationType2",
  calendarSlug: string,
  id: string,
  name: string,
  description?: string | null,
  color: string,
};

export type Reservation = {
  __typename: "Reservation",
  id: string,
  owner: string,
  from: string,
  to: string,
  type?: ReservationType | null,
  type2?: string | null,
  description: string,
  additionalInfo?: string | null,
};

export enum ReservationType {
  reservation = "reservation",
  notAvailable = "notAvailable",
  class = "class",
}


export type Membership = {
  __typename: "Membership",
  member: string,
  calendarSlug: string,
  nick?: string | null,
  email?: string | null,
  isUser: boolean,
  isAdmin: boolean,
  calendar: Calendar,
};

export type GetCalendarWithMembersQueryVariables = {
  slug: string,
};

export type GetCalendarWithMembersQuery = {
  getCalendar2?:  {
    __typename: "Calendar",
    slug: string,
    name: string,
    description?: string | null,
    owner: string,
    dayStart: number,
    dayEnd: number,
    reservationTypes?:  Array< {
      __typename: "ReservationType2",
      id: string,
      name: string,
      description?: string | null,
      color: string,
    } > | null,
    members?:  Array< {
      __typename: "Membership",
      member: string,
      email?: string | null,
      nick?: string | null,
      isUser: boolean,
      isAdmin: boolean,
    } > | null,
    myMembership?:  {
      __typename: "Membership",
      isUser: boolean,
      isAdmin: boolean,
    } | null,
  } | null,
};

export type GetCalendarWithReservationsQueryVariables = {
  slug: string,
  from: string,
  to: string,
};

export type GetCalendarWithReservationsQuery = {
  getCalendar2?:  {
    __typename: "Calendar",
    slug: string,
    name: string,
    description?: string | null,
    owner: string,
    dayStart: number,
    dayEnd: number,
    reservationTypes?:  Array< {
      __typename: "ReservationType2",
      id: string,
      name: string,
      description?: string | null,
      color: string,
    } > | null,
    reservations?:  Array< {
      __typename: "Reservation",
      id: string,
      from: string,
      to: string,
      type2?: string | null,
      description: string,
      additionalInfo?: string | null,
      type?: ReservationType | null,
      owner: string,
    } > | null,
    myMembership?:  {
      __typename: "Membership",
      isUser: boolean,
      isAdmin: boolean,
    } | null,
  } | null,
};

export type MyMembershipCalendarsQuery = {
  myMemberships?:  Array< {
    __typename: "Membership",
    member: string,
    nick?: string | null,
    email?: string | null,
    isUser: boolean,
    isAdmin: boolean,
    calendar:  {
      __typename: "Calendar",
      slug: string,
      name: string,
      description?: string | null,
      owner: string,
      dayStart: number,
      dayEnd: number,
    },
  } > | null,
};

export type RemoveCalendarMutationVariables = {
  slug: string,
};

export type RemoveCalendarMutation = {
  removeCalendar?:  {
    __typename: "Calendar",
    slug: string,
    name: string,
    description?: string | null,
    owner: string,
    dayStart: number,
    dayEnd: number,
  } | null,
};

export type PutCalendarMutationVariables = {
  slug: string,
  name: string,
  description: string,
  dayStart: number,
  dayEnd: number,
};

export type PutCalendarMutation = {
  putCalendar?:  {
    __typename: "Calendar",
    slug: string,
    name: string,
    description?: string | null,
    owner: string,
    dayStart: number,
    dayEnd: number,
  } | null,
};

export type PutReservationMutationVariables = {
  id?: string | null,
  calendarSlug: string,
  type: ReservationType,
  type2?: string | null,
  from: string,
  to: string,
  description?: string | null,
  additionalInfo?: string | null,
};

export type PutReservationMutation = {
  putReservation?:  {
    __typename: "Reservation",
    id: string,
    owner: string,
    from: string,
    to: string,
    type?: ReservationType | null,
    type2?: string | null,
    description: string,
    additionalInfo?: string | null,
  } | null,
};

export type RemoveReservationMutationVariables = {
  calendarSlug: string,
  id: string,
};

export type RemoveReservationMutation = {
  removeReservation?:  {
    __typename: "Reservation",
    id: string,
    owner: string,
    from: string,
    to: string,
    type?: ReservationType | null,
    type2?: string | null,
    description: string,
    additionalInfo?: string | null,
  } | null,
};

export type MyMembershipsQuery = {
  myMemberships?:  Array< {
    __typename: "Membership",
    member: string,
    calendarSlug: string,
    nick?: string | null,
    email?: string | null,
    isUser: boolean,
    isAdmin: boolean,
  } > | null,
};

export type MyCalendarsQuery = {
  myCalendars?:  Array< {
    __typename: "Calendar",
    slug: string,
    name: string,
    description?: string | null,
    owner: string,
    dayStart: number,
    dayEnd: number,
  } > | null,
};

export type GetCalendarQueryVariables = {
  slug: string,
};

export type GetCalendarQuery = {
  getCalendar?:  {
    __typename: "Calendar",
    slug: string,
    name: string,
    description?: string | null,
    owner: string,
    dayStart: number,
    dayEnd: number,
  } | null,
};

export type GetCalendar2QueryVariables = {
  slug: string,
};

export type GetCalendar2Query = {
  getCalendar2?:  {
    __typename: "Calendar",
    slug: string,
    name: string,
    description?: string | null,
    owner: string,
    dayStart: number,
    dayEnd: number,
  } | null,
};
