export const getCalendarWithMembers = `query GetCalendarWithMembers($slug: String!) {
  getCalendar2(slug: $slug) {
    slug
    name
    description
    owner
    dayStart
    dayEnd
    reservationTypes {
      id
      name
      description
      color
    }
    members {
      member
      email
      nick
      isUser
      isAdmin
    }
    myMembership {
      isUser
      isAdmin
    }
  }
}
`;

export const getCalendarWithReservations = `query GetCalendarWithReservations($slug: String!, $from: AWSDateTime!, $to: AWSDateTime! ) {
  getCalendar2(slug: $slug) {
    slug
    name
    description
    owner
    dayStart
    dayEnd
    reservationTypes {
      id
      name
      description
      color
    }
    reservations(from: $from, to: $to) {
      id
      from
      to
      type2
      description
      additionalInfo
      type
      owner
    }
    myMembership {
      isUser
      isAdmin
    }
  }
}
`;

export const myMembershipCalendars = `query MyMembershipCalendars {
    myMemberships {
      member
      nick
      email
      isUser
      isAdmin
      calendar {
        slug
        name
        description
        owner
        dayStart
        dayEnd
      }
    }
  }
  `;