import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify, { I18n } from 'aws-amplify'
import { conf } from './conf'

conf.Auth.oauth.redirectSignIn = `${window.location.origin}/`
conf.Auth.oauth.redirectSignOut = `${window.location.origin}/`

Amplify.configure(conf)

const labels = {
  fi: {
    'User does not exist.': 'Käyttäjää ei löytynyt',
    'Incorrect username or password.': 'Virheellinen käyttäjätunnus tai salasana.',
  }
};

I18n.setLanguage('fi');
I18n.putVocabularies(labels);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
