import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
// import { Box, LinearProgress, MenuItem, Snackbar } from '@material-ui/core';
import { putReservation, removeReservation } from '../graphql/mutations'
import { PutReservationMutationVariables, ReservationType } from '../API'
import { API, graphqlOperation } from 'aws-amplify'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import {
    DateTimePicker
} from '@material-ui/pickers'
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes'
import addMinutes from 'date-fns/addMinutes'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import lightFormat from 'date-fns/lightFormat'
import { UserIDContext, CalendarInfoContext } from './Context'

interface Props {
    initial: Partial<PutReservationMutationVariables>
    owner?: string
    onClose: (changed: boolean) => void | undefined
}
interface State {
    vars: PutReservationMutationVariables
    owner?: string
    saving: boolean
    error?: string
}

class ReservationDialog extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.save = this.save.bind(this)
        this.remove = this.remove.bind(this)
        this.validInput = this.validInput.bind(this)
        let { from, to } = this.props.initial
        if (!from) {
            from = addMinutes(roundToNearestMinutes(new Date(), { nearestTo: 15 }), 15).toISOString()
            to = undefined
        }
        if (!to) {
            to = addMinutes(new Date(from), 30).toISOString()
        }

        this.state = {
            saving: false,
            owner: props.owner,
            vars: {
                calendarSlug: props.initial.calendarSlug as string,
                type: ReservationType.reservation,
                type2: props.initial.type2,
                from,
                to,
                description: props.initial.description,
                additionalInfo: props.initial.additionalInfo,
                id: props.initial.id
            }
        }
    }

    remove() {
        const { vars } = this.state
        const { calendarSlug, id } = vars
        this.setState({ saving: true })
        const m = API.graphql(graphqlOperation(removeReservation, { calendarSlug, id })) as Promise<any>
        m.then(res => {
            this.props.onClose(true)
        }).catch(err => {
            this.props.onClose(false)
        })
    }

    save() {
        const { vars } = this.state
        if (vars.additionalInfo === '') {
            vars.additionalInfo = null
        }
        this.setState({ saving: true })
        const m = API.graphql(graphqlOperation(putReservation, vars)) as Promise<any>
        m.then(res => {
            this.setState(({ vars }) => { delete vars.id; return { vars, saving: false } })
            this.props.onClose(true)
        }).catch(err => {
            this.setState({ saving: false, error: err.errors.map((e: any) => (e.message)).join('\n') })
        })
        // this.props.onClose(true)
    }

    validInput() {
        return this.state.vars.description && this.state.vars.description.length > 0
    }

    render() {
        const { saving, vars, owner, error } = this.state
        let { description, from, to, additionalInfo, type2 } = vars
        if (!(from && to)) return
        const duration = differenceInMinutes(new Date(to), new Date(from))
        if (!additionalInfo) additionalInfo = ''
        if (!description) description = ''
        // const [day, time] = from.split('T')
        const { onClose } = this.props
        const isValid = this.validInput()
        const dFrom = new Date(from)
        return (
            <CalendarInfoContext.Consumer>{calendarInfo => (
                <UserIDContext.Consumer>
                    {userID => (
                        <Dialog
                            style={{ zIndex: 1202 }}
                            open={true}
                            onClose={() => onClose(false)}
                            // onKeyPress={evt => {
                            //     if (evt.charCode === 13 && isValid) {
                            //         this.save()
                            //     }
                            // }}
                            fullScreen={window.innerWidth < 600}
                        >
                            {saving && <LinearProgress />}
                            {error !== undefined && <Snackbar
                                open
                                onClose={() => this.setState({ error: undefined })}
                                autoHideDuration={3000}
                                message={error} />}
                            <DialogTitle>Varauksen tiedot</DialogTitle>
                            <DialogContent>
                                <Box>
                                    <DateTimePicker
                                        margin="dense"
                                        disabled={saving || !calendarInfo?.canEdit(owner)}
                                        InputProps={{
                                            readOnly: !calendarInfo?.canEdit(owner),
                                        }}
                                        minutesStep={15}
                                        ampm={false}
                                        // format="yyyy-MM-ddThh:mm"
                                        disablePast={false}
                                        value={new Date(from)}
                                        labelFunc={(d) => {

                                            if (d) {
                                                return lightFormat(new Date(d.toISOString()), 'dd.MM.yyyy HH:mm')
                                            }
                                            return ""
                                        }}
                                        onChange={(d) => {
                                            if (!d) return
                                            this.setState(({ vars }) => {
                                                const minutes = differenceInMinutes(new Date(vars.to), new Date(vars.from))
                                                vars.from = d.toISOString()
                                                vars.to = addMinutes(new Date(vars.from), minutes).toISOString()
                                                return { vars }
                                            })
                                        }}
                                        label="Alkaa"
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        disabled={saving}
                                        InputProps={{
                                            readOnly: !!vars.id && !calendarInfo?.canEdit(owner),
                                        }}
                                        margin="dense"
                                        select
                                        label="Päättyy&nbsp;(kesto)"
                                        value={duration} onChange={(e) => {
                                            const v = parseInt(e.target.value, 10)
                                            this.setState(({ vars }) => {
                                                vars.to = addMinutes(new Date(vars.from), v).toISOString()
                                                return { vars }
                                            })
                                        }}
                                    >
                                        <MenuItem key={15} value={15}>{lightFormat(addMinutes(dFrom, 15), 'HH:mm')} (15 min)</MenuItem>
                                        <MenuItem key={30} value={30}>{lightFormat(addMinutes(dFrom, 30), 'HH:mm')} (30 min)</MenuItem>
                                        <MenuItem key={45} value={45}>{lightFormat(addMinutes(dFrom, 45), 'HH:mm')} (45 min)</MenuItem>
                                        <MenuItem key={60} value={60}>{lightFormat(addMinutes(dFrom, 60), 'HH:mm')} (1 h)</MenuItem>
                                        <MenuItem key={90} value={90}>{lightFormat(addMinutes(dFrom, 90), 'HH:mm')} (1 h 30 min)</MenuItem>
                                        <MenuItem key={120} value={120}>{lightFormat(addMinutes(dFrom, 120), 'HH:mm')} (2 h)</MenuItem>
                                        <MenuItem key={150} value={150}>{lightFormat(addMinutes(dFrom, 150), 'HH:mm')} (2 h 30 min)</MenuItem>
                                        <MenuItem key={180} value={180}>{lightFormat(addMinutes(dFrom, 180), 'HH:mm')} (3 h)</MenuItem>
                                        <MenuItem key={210} value={210}>{lightFormat(addMinutes(dFrom, 210), 'HH:mm')} (3 h 30 min)</MenuItem>
                                        <MenuItem key={240} value={240}>{lightFormat(addMinutes(dFrom, 240), 'HH:mm')} (4 h)</MenuItem>
                                        <MenuItem key={270} value={270}>{lightFormat(addMinutes(dFrom, 270), 'HH:mm')} (4 h 30 min)</MenuItem>
                                        <MenuItem key={300} value={300}>{lightFormat(addMinutes(dFrom, 300), 'HH:mm')} (5 h)</MenuItem>
                                    </TextField>
                                </Box>
                                <Box>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        disabled={saving}
                                        InputProps={{
                                            readOnly: !!vars.id && !calendarInfo?.canEdit(owner),
                                        }}
                                        value={description}
                                        onChange={(e) => {
                                            const v = e.target.value
                                            this.setState(({ vars }) => {
                                                vars.description = v
                                                return { vars }
                                            })
                                        }}
                                        label="Kuvaus"
                                        error={!isValid}
                                        helperText={isValid ? '' : 'Pakollinen tieto'} />
                                </Box>
                                <Box>
                                    <TextField
                                        fullWidth
                                        margin="dense"
                                        multiline
                                        disabled={saving}
                                        InputProps={{
                                            readOnly: !!vars.id && !calendarInfo?.canEdit(owner),
                                        }}
                                        value={additionalInfo}
                                        onChange={(e) => {
                                            const v = e.target.value
                                            this.setState(({ vars }) => {
                                                vars.additionalInfo = v
                                                return { vars }
                                            })
                                        }}
                                        label="Lisätietoja" />
                                </Box>
                                {calendarInfo && calendarInfo.ts.length > 0 && (
                                    <Box>
                                        <TextField
                                            disabled={saving}
                                            InputProps={{
                                                readOnly: !!vars.id && !calendarInfo?.canEdit(owner),
                                            }}
                                            margin="dense"
                                            select
                                            label="Tyyppi"
                                            value={type2}
                                            onChange={(e) => {
                                                this.setState(({ vars }) => {
                                                    vars.type2 = e.target.value
                                                    return { vars }
                                                })
                                            }}
                                        >   
                                            {calendarInfo.ts.map((t,i) => (
                                                <MenuItem selected={i===0} key={t.id} value={t.id}>{t.name}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                )}
                            </DialogContent>
                            <DialogActions>
                                {(calendarInfo?.canEdit(owner) || !vars.id) && (
                                    <Button
                                        disabled={saving || !isValid}
                                        onClick={this.save}
                                        aria-label="save reservation"
                                        startIcon={<SaveIcon />}
                                        color="primary"
                                        variant="contained"
                                    >
                                        Tallenna
                                </Button>)}
                                {(calendarInfo?.canEdit(owner) && vars.id) && (
                                    <Button
                                        disabled={saving}
                                        onClick={this.remove}
                                        aria-label="delete reservation"
                                        startIcon={<DeleteIcon />}
                                    >
                                        Poista
                                </Button>
                                )}
                                <Button
                                    disabled={saving}
                                    onClick={() => onClose(false)}
                                    aria-label="close dialog"
                                    startIcon={<CancelIcon />}
                                >
                                    Sulje
                                {/* <CancelIcon color="primary" fontSize="default" /> */}
                                </Button>
                                {/* <Button disabled={saving} onClick={this.save} variant="contained" color="primary">Tallenna</Button>
                            <Button disabled={saving} onClick={() => onClose(false)}>Peru</Button> */}
                            </DialogActions>
                            {saving && <LinearProgress />}
                        </Dialog>
                    )}
                </UserIDContext.Consumer>
            )}</CalendarInfoContext.Consumer>
        )
    }
}



export default ReservationDialog