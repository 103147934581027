import React from 'react';
import Box from '@material-ui/core/Box';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from './Context'

const useStyles = makeStyles({
  root: {
    display: 'block',
    padding: '8px 16px',
    verticalAlign: 'middle',
    textDecoration: 'none',
    color: 'inherit',
    backgroundColor: 'inherit',
    cursor: 'pointer',
    fontSize: '102%',
    '&:hover': {
      background: "#eee",
    },
  },
});

export const Menu: React.FC<any> = (props) => {
  const classes = useStyles();
  return (
    <UserContext.Consumer>
      {(user) => (
        <Box paddingTop="4.5rem" width="270px">
          <div style={{ marginBottom: '0.5rem' }}>
            <Link className={classes.root} to="/">Etusivu</Link>
            {!!user && [
              <div key="reservationlink" className={classes.root}>Varaustyypit (TODO)</div>,
              <div key="sharinglink" className={classes.root}>Jakaminen (TODO)</div>,
              <div key="membershiplink" className={classes.root}>Jäsenyydet (TODO)</div>
            ]}
          </div>
          <hr />
          <Link className={classes.root} style={{ marginTop: '0.5rem' }} to="/terms">Käyttöehdot</Link>
          <Link className={classes.root} to="/privacypolicy">Tietosuojaseloste</Link>
          {/* {!!user && <Link className={classes.root} to="/settings">Omat tiedot</Link>} */}
        </Box>
      )}
    </UserContext.Consumer>
  )
}
