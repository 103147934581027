import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Content } from './Content'

export const Terms: React.FC<any> = (props) => {
  return <Content>
    <Typography variant="h4">Käyttöehdot</Typography>
    <p><b>12.1.2020, LUONNOS</b></p>
    {/* https://www.huuto.net/kayttoehdot */}
    
    <Typography variant="h6">Käyttöehtojen soveltaminen ja palvelun kuvaus</Typography>
    <Typography variant="h6">Käyttöoikeus ja käyttäjätiedot</Typography>
    <Typography variant="h6">Käyttäjän velvollisuudet ja vastuu</Typography>
    <Typography variant="h6">Palveluntarjoajan oikeudet ja vastuu</Typography>
    <Typography variant="h6">Käyttöehtojen muuttaminen</Typography>
    <Typography variant="h6">Sopimuksen voimassaolo ja päättyminen</Typography>
    <Typography variant="h6">Sopimuksen siirtäminen</Typography>
    <Typography variant="h6">Evästeet</Typography>
    <Typography variant="h6">Sovellettava laki ja riitojen ratkaisu</Typography>
  </Content>
}
