/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const removeCalendar = /* GraphQL */ `
  mutation RemoveCalendar($slug: String!) {
    removeCalendar(slug: $slug) {
      slug
      name
      description
      owner
      dayStart
      dayEnd
    }
  }
`;
export const putCalendar = /* GraphQL */ `
  mutation PutCalendar(
    $slug: String!
    $name: String!
    $description: String!
    $dayStart: Int!
    $dayEnd: Int!
  ) {
    putCalendar(
      slug: $slug
      name: $name
      description: $description
      dayStart: $dayStart
      dayEnd: $dayEnd
    ) {
      slug
      name
      description
      owner
      dayStart
      dayEnd
    }
  }
`;
export const putReservation = /* GraphQL */ `
  mutation PutReservation(
    $id: ID
    $calendarSlug: String!
    $type: ReservationType!
    $type2: String
    $from: AWSDateTime!
    $to: AWSDateTime!
    $description: String
    $additionalInfo: String
  ) {
    putReservation(
      id: $id
      calendarSlug: $calendarSlug
      type: $type
      type2: $type2
      from: $from
      to: $to
      description: $description
      additionalInfo: $additionalInfo
    ) {
      id
      owner
      from
      to
      type
      type2
      description
      additionalInfo
    }
  }
`;
export const removeReservation = /* GraphQL */ `
  mutation RemoveReservation($calendarSlug: String!, $id: ID!) {
    removeReservation(calendarSlug: $calendarSlug, id: $id) {
      id
      owner
      from
      to
      type
      type2
      description
      additionalInfo
    }
  }
`;
