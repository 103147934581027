import React from 'react';
import { CognitoUser } from '@aws-amplify/auth'
import { GetCalendarWithReservationsQuery } from '../API';


interface ReservationType2 {
    __typename: "ReservationType2";
    id: string;
    name: string;
    description?: string | null | undefined;
    color: string;
}

export class CalendarInfo {
    ts: ReservationType2[]
    ownerID?: string
    userID?: string
    isUser: boolean
    isAdmin: boolean 
    constructor(
        calendar: GetCalendarWithReservationsQuery,
        user: CognitoUser|undefined,
    ) {
        this.ownerID = calendar.getCalendar2?.owner
        //@ts-ignore
        this.userID = user?.attributes.sub
        this.ts = calendar.getCalendar2?.reservationTypes || []
        this.isUser = !!calendar.getCalendar2?.myMembership?.isUser
        this.isAdmin = !!calendar.getCalendar2?.myMembership?.isAdmin
    }
    getById(id: string): ReservationType2 | undefined {
        for (let t of this.ts) {
            if (t.id === id) return t
        }
        return undefined
    }
    canEdit(entryOwner:string|undefined): boolean {
        if (entryOwner === this.userID) return true
        if (this.ownerID === this.userID) return true
        return this.isAdmin
    }
    canAdd():boolean {
        if (this.ownerID === this.userID) return true
        return this.isAdmin || this.isUser

    }
}


export const UserIDContext = React.createContext(undefined as string | undefined)
export const UserContext = React.createContext(undefined as CognitoUser | undefined)
export const CalendarInfoContext = React.createContext(undefined as CalendarInfo | undefined)
