import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Content } from './Content'

export const PrivacyPolicy: React.FC<any> = (props) => {
  return <Content>
    <Typography variant="h4">Tietosuojaseloste</Typography>
    <p><b>24.10.2021</b></p>
    <Typography variant="h6">Rekisterinpitäjä</Typography>
    <p>Juhani Ränkimies, juhani@juranki.com</p>

    <Typography variant="h6">Rekisterin nimi</Typography>
    <p>Käyttäjärekisteri</p>

    <Typography variant="h6">Oikeusperuste ja henkilötietojen käsittelyn tarkoitus</Typography>
    <ul>
    <li>käyttöoikeuksien hallinta</li>
    <li>henkilön suostumus</li>
    </ul>
    <p>Tietoja ei käytetä automatisoituun päätöksentekoon tai profilointiin. </p>

    <Typography variant="h6">Rekisterin tietosisältö</Typography>
    <ul>
      <li>Nimi</li>
      <li>Sähköpostiosoite</li>
      <li>Profiilikuva</li>
    </ul>

    <Typography variant="h6">Säännönmukaiset tietolähteet</Typography>
    <p>
      Rekisteriin tallennettavat tiedot saadaan asiakkaalta mm. www-lomakkeilla lähetetyistä viesteistä, 
      sähköpostitse, puhelimitse, sosiaalisen median palvelujen kautta, sopimuksista, asiakastapaamisista ja 
      muista tilanteista, joissa asiakas luovuttaa tietojaan
    </p>

    <Typography variant="h6">Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai ETA:n ulkopuolelle</Typography>
    <p>Tietoja ei luovuteta säännönmukaisesti muille tahoille. Tietoja voidaan julkaista siltä osin kuin niin on sovittu asiakkaan kanssa.</p>
    <p>Tietoja voidaan siirtää rekisterinpitäjän toimesta myös EU:n tai ETA:n ulkopuolelle.</p>

    <Typography variant="h6">Rekisterin suojauksen periaatteet</Typography>
    <p>
      Rekisterin käsittelyssä noudatetaan huolellisuutta ja tietojärjestelmien avulla
      käsiteltävät tiedot suojataan asianmukaisesti. 
    </p>
    <p>
      Rekisteritiedot säilytetään Amazon AWS Cognito -palvelussa Irlannissa. Tiedot salataan
      siirron ja tallennuksen aikana.
      
    </p>
    <p>
    Rekisteriin tallennettuja tietoja sekä palveluiden käyttöoikeuksia ja muita
     henkilötietojen turvallisuuden kannalta kriittisiä tietoja käsitellään 
     luottamuksellisesti ja vain niiden työntekijöiden toimesta, joiden työnkuvaan se kuuluu.
    </p>

    <Typography variant="h6">Tarkastusoikeus, tiedon korjaaminen ja poistaminen</Typography>

    <p>Lähetä pyyntö sähköpostitse rekisterin pitäjälle (Juhani Ränkimies, juhani@juranki.com).</p>
  </Content>
}
