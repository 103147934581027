import React from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import { fi } from 'date-fns/locale'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import subDays from 'date-fns/subDays'
import addMinutes from 'date-fns/addMinutes'
import startOfISOWeek from 'date-fns/startOfISOWeek'
import endOfISOWeek from 'date-fns/endOfISOWeek'
import getISOWeekYear from 'date-fns/getISOWeekYear'
import getISOWeek from 'date-fns/getISOWeek'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom'
import { UserIDContext, CalendarInfoContext } from './Context'
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Swipeable } from 'react-swipeable'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        floatHead: {
            gridRow: 'days',
            position: 'sticky',
            // top: 0,
            textAlign: 'center',
            borderLeft: '1px solid #dddddd',
            top: '3.3rem',
            [theme.breakpoints.up('sm')]: {
                top: '4rem',
            },
            backgroundColor: 'rgba(255,255,255,0.7)'
        },
    }),
);

const rowHeight = 24

function mkGridTemplateRows(dayStart: number, dayEnd: number): string {
    const rows = ['[days] auto']
    let t = new Date(2000, 1, 1, 0, 0)
    while (rows.length < 4 * (dayEnd - dayStart) + 2) {
        if (t.getHours() < dayStart || t.getHours() > dayEnd) {
            t = addMinutes(t, 15)
            continue
        }

        rows.push(`[time-${format(t, 'HHmm')}] 1fr`)
        t = addMinutes(t, 15)
    }
    return rows.join('\n')
}

function mkGridTemplateCols(large:boolean): string {
    const cols = large ? ['[times] 2.8rem'] : ['[times] 1.4rem']
    for (let i = 0; i < 7; i++) {
        cols.push(`[day-${i}] 1fr`)
    }
    return cols.join('\n')
}

export interface Entry {
    id: string
    from: Date
    to: Date
    type2: string | null | undefined
    description: string
    additionalInfo: string
    owner: string
}

export class Day {
    title: string
    shortTitle: string
    shortTitle2: string
    entries: Entry[]
    from: Date
    to: Date
    constructor(d: Date) {
        this.title = format(d, 'cccccc d.M', { locale: fi })
        this.shortTitle = format(d, 'cccccc', { locale: fi })
        this.shortTitle2 = format(d, 'd.M', { locale: fi })
        this.entries = []
        this.from = startOfDay(d)
        this.to = endOfDay(d)
    }
    futureSlots(dayStart: number, dayEnd: number): Date[] {
        const now = new Date()
        return Array
            .from(Array(24 * 4))
            .map((v, i) => (addMinutes(this.from, i * 15)))
            .filter(d => (this.entries.every(e => (!(d >= e.from && d < e.to)))))
            .filter(d => (d > now))
            .filter(d => (d.getHours() >= dayStart))
            .filter(d => (d.getHours() < dayEnd))
    }
    pastSlots(dayStart: number, dayEnd: number): Date[] {
        const now = new Date()
        return Array
            .from(Array(24 * 4))
            .map((v, i) => (addMinutes(this.from, i * 15)))
            .filter(d => (this.entries.every(e => (!(d >= e.from && d < e.to)))))
            .filter(d => (d <= now))
            .filter(d => (d.getHours() >= dayStart))
            .filter(d => (d.getHours() < dayEnd))
    }
}

export class Week {
    title: string
    from: Date
    to: Date
    days: Day[]

    constructor(d: Date) {
        // console.log('week constructor', d)
        this.title = format(d, 'I/RRRR', { locale: fi })
        this.from = startOfISOWeek(d)
        this.to = endOfISOWeek(d)
        this.days = []

        let i = this.from
        while (i <= this.to) {
            this.days.push(new Day(i))
            i = addDays(i, 1)
        }
    }

    resetEntries(): void {
        this.days.forEach(d => {
            d.entries = []
        })
    }

    addEntry(entry: Entry): void {
        this.days.forEach(d => {
            if (d.from <= entry.from && d.to >= entry.from) {
                d.entries.push(entry)
            }
        })
    }
}

export interface WeekProps {
    week: Week,
    // setWeek(d: Date): void
    basePath: string
    dayStart: number
    dayEnd: number
    add: (d: Date) => void
    edit: (e: Entry) => void
}

export const WeekComponent: React.FC<WeekProps> = (props) => {
    const classes = useStyles();
    const large = useMediaQuery('(min-width:600px)');
    const { week, dayStart, dayEnd } = props
    const nextWeek = addDays(week.from, 7)
    const prevWeek = subDays(week.from, 7)
    // console.log("weeks", week.from, nextWeek, prevWeek)
    // console.log(`${props.basePath}/w/${getISOWeekYear(nextWeek)}/${getISOWeek(nextWeek)}`)
    let t = new Date(2000, 1, 1, 0, 0)
    t.setHours(dayStart)
    return <CalendarInfoContext.Consumer>{calendarInfo => (
        <UserIDContext.Consumer>
            {userID => (
                <Box>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-around"
                        alignItems="center">
                        <Link id="prevLink" to={`${props.basePath}/w/${getISOWeekYear(prevWeek)}/${getISOWeek(prevWeek)}`}>
                            <IconButton aria-label="previous week">
                                <ArrowBackIosIcon />
                            </IconButton>
                        </Link>
                        <Typography variant="h6" component="h4">Viikko {week.title}</Typography>
                        <Link id="nextLink" to={`${props.basePath}/w/${getISOWeekYear(nextWeek)}/${getISOWeek(nextWeek)}`}>
                            <IconButton aria-label="next week">
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </Link>
                    </Box>
                    <Swipeable
                        delta={100}
                        onSwiped={e => {
                            if (e.dir === 'Right') {
                                document.getElementById('prevLink')?.click()
                            }
                            if (e.dir === 'Left') {
                                document.getElementById('nextLink')?.click()
                            }
                        }}
                    >
                        <Box
                            display="grid"
                            style={{
                                height: `${(4 * (dayEnd - dayStart) + 2) * rowHeight}px`,
                                gridTemplateRows: mkGridTemplateRows(dayStart, dayEnd),
                                gridTemplateColumns: mkGridTemplateCols(large),
                                // overflowY: 'scroll'
                            }}
                        >
                            {Array.from(Array(dayEnd - dayStart)).map(i => {
                                const s = large ? format(t, "HH:mm") : format(t, "HH")
                                const rowStart = `time-${format(t, 'HHmm', { locale: fi })}`
                                t = addMinutes(t, 15)
                                const r1 = `time-${format(t, 'HHmm', { locale: fi })}`
                                t = addMinutes(t, 15)
                                const r2 = `time-${format(t, 'HHmm', { locale: fi })}`
                                t = addMinutes(t, 15)
                                const r3 = `time-${format(t, 'HHmm', { locale: fi })}`
                                t = addMinutes(t, 15)

                                return [
                                    <div key={rowStart}
                                        style={{
                                            gridColumn: "times",
                                            gridRow: rowStart,
                                            borderTop: '1px solid #dddddd',
                                            // gridRowEnd: rowEnd
                                        }}>
                                        {s}
                                    </div>,
                                    <div key={r1}
                                        style={{
                                            gridColumn: "times",
                                            gridRow: r1,
                                            // gridRowEnd: rowEnd
                                        }}>
                                        &nbsp;
                            </div>,
                                    <div key={r2}
                                        style={{
                                            gridColumn: "times",
                                            gridRow: r2,
                                            // gridRowEnd: rowEnd
                                        }}>
                                        &nbsp;
                            </div>,
                                    <div key={r3}
                                        style={{
                                            gridColumn: "times",
                                            gridRow: r3,
                                            // gridRowEnd: rowEnd
                                        }}>
                                        &nbsp;
                            </div>
                                ]
                            })}
                            {week.days.map((d, di) => {
                                return [
                                    <Box key={`day-${di}`} className={classes.floatHead} style={{
                                        gridColumn: `day-${di}`,
                                    }}>
                                        <div style={{fontSize:'110%'}}>{large ? d.title : <span>{d.shortTitle}<br/>{d.shortTitle2}</span>}</div>
                                    </Box>].concat(d.entries.map((e: Entry) => {
                                        // Entryt
                                        if (e.from.getHours() < dayStart || e.from.getHours() > dayEnd) {
                                            // don't show entries outside calendar limits
                                            return <div style={{ display: "none" }}></div>
                                        }
                                        let endRow = `time-${format(e.to, 'HHmm', { locale: fi })}`
                                        if (differenceInMinutes(e.to, d.from) >= 1440) {
                                            endRow = 'time-2400'
                                        }
                                        const bg = calendarInfo?.getById(e.type2 || '')?.color || '#c5cae9'
                                        return (
                                            <Box
                                                key={e.id}
                                                borderRadius={5}
                                                // margin='1px'
                                                padding='3px'
                                                // height={`${differenceInMinutes(e.to, e.from) / 15 * rowHeight - 6}px`}
                                                height='100%'
                                                overflow="hidden"
                                                style={{
                                                    gridColumn: `day-${di}`,
                                                    gridRowStart: `time-${format(e.from, 'HHmm', { locale: fi })}`,
                                                    gridRowEnd: endRow,
                                                    cursor: 'pointer',
                                                    backgroundColor: bg,
                                                    border: "0.5px solid #aaaaaa"
                                                }}
                                                onClick={() => {
                                                    props.edit(e)
                                                }}
                                            >
                                                {/* <Box> */}
                                                {/* {format(e.from, 'HH:mm', { locale: fi })}-{format(e.to, 'HH:mm', { locale: fi })}:  */}
                                                {e.description}
                                                {/* </Box> */}
                                            </Box>
                                        )
                                    }), d.futureSlots(dayStart, dayEnd).map(s => (
                                        // <Tooltip
                                        //     key={`day-${di}-${s.toISOString()}`}
                                        //     title={`Uusi varaus: ${format(s, 'EEEE, HH:mm', { locale: fi })}`}
                                        // >
                                        <div
                                            key={`day-${di}-${s.toISOString()}`}
                                            style={{
                                                gridColumn: `day-${di}`,
                                                gridRow: `time-${format(s, 'HHmm', { locale: fi })}`,
                                                // background: '#ffffff',
                                                cursor: calendarInfo?.canAdd() ? 'pointer' : 'auto',
                                                border: '0.5px solid #eee'
                                                // borderBottom: '1px solid #dddddd',
                                                // borderLeft: '1px solid #dddddd',
                                            }}
                                            onClick={() => {
                                                if (props.add && calendarInfo?.canAdd()) {
                                                    props.add(s)
                                                }
                                            }}
                                        ></div>
                                        // </Tooltip>
                                    )), d.pastSlots(dayStart, dayEnd).map(s => (
                                        <div
                                            key={`day-${di}-${s.toISOString()}`}
                                            style={{
                                                gridColumn: `day-${di}`,
                                                gridRow: `time-${format(s, 'HHmm', { locale: fi })}`,
                                                background: '#f9f9f9',
                                                border: '0.5px solid #eee'
                                                // cursor: 'pointer',
                                                // borderBottom: '1px solid #dddddd',
                                                // borderLeft: '1px solid #dddddd',
                                            }}
                                            onClick={() => {
                                                if (props.add && calendarInfo?.canAdd()) {
                                                    props.add(s)
                                                }
                                            }}
                                        ></div>
                                    )))
                            }
                            )}
                        </Box>
                    </Swipeable>
                </Box>
            )}

        </UserIDContext.Consumer>
    )}</CalendarInfoContext.Consumer>
}