import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Content } from './Content'
import { UserContext } from './Context'
// import Auth, { CognitoUser } from '@aws-amplify/auth'

function dump(user:any):any {
  return JSON.stringify(user.signInUserSession.idToken.payload, null, 2)
}


export const Settings: React.FC<any> = (props) => {
  //@ts-ignore
  // const [u, setU] = React.useState(undefined as CognitoUser)
  // React.useEffect(() => {
  //   Auth.currentAuthenticatedUser().then((u:CognitoUser) => {
  //     setU(u)
  //   })
  // })
  return <UserContext.Consumer>
    {(user) => (
      <Content>
        <Typography variant="h4">Omat tiedot</Typography>
        <p>Tätä tietoa voi käyttää sovelluksessa esiintyvien ongelmien selvittämiseen</p>

        <pre style={{fontSize:'90%'}}>{dump(user)}</pre>
        {/* {!!u && (
          <div>{u.getUsername()}</div>
        )}
        <pre style={{fontSize:'90%'}}>{JSON.stringify(u, null, 2)}</pre> */}
      </Content>
    )}
  </UserContext.Consumer >
}
