import React, { Component } from 'react';
import { styled, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Auth from '@aws-amplify/auth'
import { Hub } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import MyCalendars from './components/MyCalendars';
import Calendar from './components/Calendar';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { fi } from 'date-fns/locale'
import { UserIDContext, UserContext } from './components/Context'
import { SignButton, AuthStatus } from './components/Auth'
import { Content } from './components/Content';
import { PrivacyPolicy } from './components/PrivacyPolicy'
import { Terms } from './components/Terms'
import { Menu } from './components/Menu'
import { Settings } from './components/Settings'
import { EditCalendar } from './components/EditCalendar';

// @ts-ignore
window.Auth = Auth

const theme = createMuiTheme()
theme.palette.primary.light = '#388e3c'
theme.palette.primary.main = '#0277bd'
theme.palette.secondary.light = '#ffc107'
theme.zIndex.appBar = 1201
theme.zIndex.drawer = 1200

const Title = styled(Typography)({
  flexGrow: 1
})
const PaddedButton = styled(IconButton)({
  marginRight: '1rem'
})

interface State {
  userID?: string
  authStatus: AuthStatus
  user?: CognitoUser
  drawer: boolean
}
type Props = {}

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { userID: undefined, authStatus: 'initial', drawer: false }
    Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
      this.setState({ userID: user.getUsername(), authStatus: 'signedIn', user })
    }).catch(err => {
      this.setState({ authStatus: 'signedOut', userID: undefined, user: undefined })
    })
    Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'customOAuthState') {
        const path = decodeURIComponent(encodeURIComponent(window.atob(payload.data)))
        if (window.location.pathname !== path) {
          window.location.assign(path)
        }
      }
      Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
        this.setState({ userID: user.getUsername(), authStatus: 'signedIn', user })
      }).catch(err => {
        this.setState({ authStatus: 'signedOut', userID: undefined, user: undefined })
      })
    })
    this.toggleDrawer = this.toggleDrawer.bind(this)
  }

  toggleDrawer() {
    this.setState(({ drawer }) => ({ drawer: !drawer }))
  }

  render() {
    const { userID, authStatus, drawer, user } = this.state
    return (
      <React.Fragment>
        <UserIDContext.Provider value={userID}>
          <UserContext.Provider value={user}>
            <MuiPickersUtilsProvider locale={fi} utils={DateFnsUtils}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                  <AppBar>
                    <Toolbar>
                      <PaddedButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={this.toggleDrawer}
                      >
                        <MenuIcon />
                      </PaddedButton>
                      <Title variant="h6">
                        <NavLink to="/" style={{ textDecoration: "none", color: "white" }}>varaus.net</NavLink>
                      </Title>
                      <SignButton authstatus={authStatus} color="inherit" />
                    </Toolbar>
                  </AppBar>
                  <Drawer style={{ zIndex: 1200 }} open={drawer} onClick={this.toggleDrawer}>
                    <Menu />
                  </Drawer>
                  <Switch>
                    <Route path="/terms" component={Terms} />
                    <Route path="/privacypolicy" component={PrivacyPolicy} />
                    {authStatus === 'signedIn' && [
                      <Route key="calendarWeek" path="/c/:slug/w/:year/:weekOfYear" component={Calendar} />,
                      <Route key="calendarEdit" path="/c/:slug/edit" component={EditCalendar} />,
                      <Route key="calendarMain" path="/c/:slug" component={Calendar} />,
                      <Route key="settings" path="/settings" component={Settings} />,
                      <Route key="home" path="/" component={MyCalendars} />
                    ]}
                    {authStatus === 'signedOut' && [
                      <Route key="home" path="/">
                        <Content>
                          <p>
                            TODO:
                    </p>
                          <ul>
                            <li>Esittely</li>
                            <li>Ohjeita</li>
                            <li>Käyttöehdot ja henkilötietojen käsittely</li>
                            <li>Julkiset kalenterit</li>
                          </ul>
                        </Content>
                      </Route>
                    ]}
                  </Switch>
                </Router>
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </UserContext.Provider>
        </UserIDContext.Provider>
      </React.Fragment>
    );
  }
}


export default App