import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
// import { Button, Box, LinearProgress, Snackbar } from '@material-ui/core';
import { putCalendar } from '../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

type Props = {
    slug?: string
    name?: string
    description?: string | null
    dayStart?: number
    dayEnd?: number
    onClose: (changed: boolean) => void | undefined
}
type State = {
    slug?: string
    name?: string
    description?: string | null
    dayStart?: number
    dayEnd?: number
    saving: boolean
    errorMessage?: string
}

class NewCalendarDialog extends Component<Props, State> {
    state: State = { saving: false }
    constructor(props: Props) {
        super(props)
        this.save = this.save.bind(this)
        this.state = {
            saving: false,
            name: props.name,
            description: props.description,
            dayStart: props.dayStart,
            dayEnd: props.dayEnd,
            slug: props.slug,
            errorMessage: undefined
        }
    }
    save() {
        let { slug, name, description, dayStart, dayEnd } = this.state
        if (!slug || slug.length === 0) {
            slug = this.slugPlaceholder()
        }
        this.setState({ saving: true })
        API.graphql(graphqlOperation(putCalendar, { slug, name, description, dayStart, dayEnd })).then((r: any) => {
            this.setState({saving:false})
            this.props.onClose(true)
        }).catch((err: any) => {
            if (err.errors[0].message.includes('ConditionalCheckFailedException')) {
                this.setState({ errorMessage: 'tunniste on jo käytössä', saving: false })
            } else {
                this.setState({ errorMessage: err.errors.map((e: any) => (e.message)).join('\n'), saving: false })
            }
        })
        // this.props.onClose(true)
    }

    static getDerivedStateFromProps(nextProps: { [k: string]: any }, prevState: { [k: string]: any }) {
        const ks = ['slug', 'name', 'description', 'dayStart', 'dayEnd']
        const rv: { [k: string]: any } = {}
        ks.forEach(k => {
            if (nextProps[k] !== prevState[k]) {
                rv[k] = nextProps[k]
            }
        })

        if (rv.length > 0) {
            return rv
        }
        return null
    }

    slugPlaceholder(): string {
        const { name } = this.state
        if (!name) return ''
        if (name.length === 0) return ''
        let rv = name.toLowerCase()
        rv = rv.replace(/[ _]/g, '-').replace(/[äå]/g, 'a').replace(/ö/g, 'o')
        rv = rv.replace(/[^a-z0-9-]/g, '')
        return rv.substr(0, 63)
    }

    slugError(slug: string): string | undefined {
        if (slug.search(/[^a-z0-9-]/g) > -1) return 'saa sisältää vain merkkejä a-z, 0-9 ja -'
        if (slug.startsWith('-')) return 'ei saa alkaa merkillä -'
        if (slug.endsWith('-')) return 'ei saa loppua merkkiin -'
        if (slug.length > 63) return 'ei saa olla 63 merkkiä pitempi'
        if (slug.length === 0) return 'pakollinen tieto'
    }
    nameError(name: string): string | undefined {
        if (name.length === 0) return 'pakollinen tieto'
    }

    render() {
        const { saving, slug, name, description, dayStart, dayEnd, errorMessage } = this.state
        const slugPlaceholder = this.slugPlaceholder()
        const slugError = this.slugError(slug || slugPlaceholder)
        const nameError = this.nameError(name || '')
        const descriptionError = this.nameError(description || '')
        const hasError = (slugError !== undefined || nameError !== undefined || descriptionError !== undefined)
        const daySliderValue = [
            dayStart ? dayStart : 0,
            dayEnd || 24
        ]
        const { onClose } = this.props
        return (
            <Dialog
                style={{ zIndex: 1202 }}
                open={true}
                onClose={() => onClose(false)}
                fullScreen={window.innerWidth < 600}
            >
                {errorMessage !== undefined && <Snackbar
                    open
                    onClose={() => this.setState({ errorMessage: undefined })}
                    autoHideDuration={3000}
                    message={errorMessage} />}
                {saving && <LinearProgress />}
                <DialogTitle>Kalenterin tiedot</DialogTitle>
                <DialogContent>
                    <Box>
                        <TextField
                            error={nameError ? true : false}
                            helperText={nameError}
                            required
                            disabled={saving}
                            value={name}
                            onChange={e =>
                                this.setState({ name: e.target.value })
                            }
                            label="Nimi" />
                    </Box>
                    <Box>
                        <TextField
                            error={slugError ? true : false}
                            helperText={slugError}
                            InputLabelProps={{ shrink: (slug || slugPlaceholder)?.length > 0 }}
                            required
                            disabled={saving}
                            value={slug || slugPlaceholder}
                            onChange={e =>
                                this.setState({ slug: e.target.value })
                            }
                            label="Tunniste" />
                    </Box>
                    <Box>
                        <TextField
                            error={descriptionError ? true : false}
                            helperText={descriptionError}
                            disabled={saving}
                            value={description}
                            onChange={e =>
                                this.setState({ description: e.target.value })
                            }
                            label="Kuvaus" />
                    </Box>
                    <Box >
                        <Typography id="range-slider" variant="caption" gutterBottom>
                            Varattavissa oleva aika:
                            {daySliderValue[0]}:00 - {daySliderValue[1]}:00
                        </Typography>
                        <Slider
                            disabled={saving}
                            value={daySliderValue}
                            valueLabelDisplay="auto"
                            max={24}
                            min={0}
                            onChange={(e, v) => {
                                const vv = v as number[]
                                this.setState({
                                    dayStart: vv[0],
                                    dayEnd: vv[1]
                                })
                            }} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={saving || hasError}
                        onClick={this.save}
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                    >
                        Tallenna
                    </Button>
                    <Button
                        disabled={saving}
                        onClick={() => onClose(false)}
                        startIcon={<CancelIcon />}
                    >
                        Peru
                    </Button>
                </DialogActions>
                {saving && <LinearProgress />}
            </Dialog>
        )
    }
}



export default NewCalendarDialog