
import React from 'react'
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: '4rem',
            [theme.breakpoints.down('sm')]: {
                padding: '0px',
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: '1rem'
            }
        },
        paper: {
            [theme.breakpoints.down('sm')]: {
                padding: '0.3rem',
            },
            [theme.breakpoints.up('sm')]: {
                padding: '2rem',
            },
        }
    }),
);
// export const Content = styled(Container)({
//     paddingTop: '1rem'
// })

export const Content: React.FC<any> = (props) => {
    const classes = useStyles();
    return <Container className={classes.root}>
        <Paper className={classes.paper}>
            {props.children}
        </Paper>
    </Container>
}