
import React from 'react';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { I18n } from 'aws-amplify'
import Button, { ButtonProps } from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Box from '@material-ui/core/Box';
import { FederatedSignInOptions } from '@aws-amplify/auth/lib-esm/types';
import { UserContext } from './Context'
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useHistory } from "react-router-dom";


export type AuthStatus = 'initial' | 'signedIn' | 'signedOut'

export interface SignButtonProps {
  authstatus: AuthStatus
}

const signButtonText = {
  initial: '...',
  signedIn: 'Kirjaudu ulos',
  signedOut: 'Kirjaudu'
}

enum SignStatus {
  None,
  SignIn,
  SignInEmail,
  SignUp,
  VerifyEmail,
  ForgotPassword,
  ResetPassword
}
function checkSignUpMerge() {
  if (window.location.search.startsWith('?error_description=Already+found+an+entry+for+username+Facebook_')) {
    if (!localStorage.getItem('retry_facebook')) {
      localStorage.setItem('retry_facebook', 'true')
      //@ts-ignore
      Auth.federatedSignIn({ provider: 'Facebook' })
    }
  } else {
    localStorage.removeItem('retry_facebook')
  }
  if (window.location.search.startsWith('?error_description=Already+found+an+entry+for+username+Google_')) {
    if (!localStorage.getItem('retry_google')) {
      localStorage.setItem('retry_google', 'true')
      //@ts-ignore
      Auth.federatedSignIn({ provider: 'Google' })
    }
  } else {
    localStorage.removeItem('retry_google')
  }
}

function userImage(u: any): any {
  const attrs = u.signInUserSession.idToken.payload
  if (attrs.picture) {
    if (attrs.picture.startsWith('{')) {
      return JSON.parse(attrs.picture).data.url
    }
    return attrs.picture
  }
}
function userInitials(u: any): string {
  const l1 = u.signInUserSession.idToken.payload.given_name?.substring(0, 1) || ""
  const l2 = u.signInUserSession.idToken.payload.family_name?.substring(0, 1) || ""
  return l1 + l2
}


export const SignButton: React.FC<Partial<ButtonProps & SignButtonProps>> = (props) => {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [status, setStatus] = React.useState(SignStatus.None)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = !!anchorEl
  
  let history = useHistory();
  
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    Auth.signOut();
    setAnchorEl(null);
  };
  const toSettings = () => {
    history.push('/settings')
    setAnchorEl(null);
  };
  checkSignUpMerge()
  return <UserContext.Consumer>
    {(user) => (
      <Box display='flex'>

        {!user && <Button {...props}
          onClick={() => {
            if (props.authstatus === 'signedOut') {
              setStatus(SignStatus.SignIn)
            }
            if (props.authstatus === 'signedIn') {
              Auth.signOut()
            }
          }}
        >
          {signButtonText[props.authstatus!]}
        </Button>}

        {user && (
          <div>
            {userImage(user) ? (
              <Avatar
                src={userImage(user)}
                style={{
                  cursor: 'pointer',
                  marginLeft: '0.3rem'
                }}
                onClick={handleMenu}
              />
            ) : (
                <Avatar
                  style={{
                    cursor: 'pointer',
                    backgroundColor: "#bbdefb",
                    color: '#0277bd',
                    marginLeft: '0.3rem'
                  }}
                  onClick={handleMenu}
                >{userInitials(user)}</Avatar>)
            }

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={toSettings}>Omat tiedot</MenuItem>
              <MenuItem onClick={signOut}>Kirjaudu ulos</MenuItem>
            </Menu>
          </div>)
        }

        <SignInDialog
          open={status === SignStatus.SignIn}
          onCancel={() => setStatus(SignStatus.None)}
          onSuccess={() => setStatus(SignStatus.None)}
          toEmail={() => setStatus(SignStatus.SignInEmail)} />
        <SignInEmailDialog
          open={status === SignStatus.SignInEmail}
          onCancel={() => setStatus(SignStatus.None)}
          onSuccess={() => setStatus(SignStatus.None)}
          toRegistration={() => setStatus(SignStatus.SignUp)}
          toForgotPassword={() => setStatus(SignStatus.ForgotPassword)} />
        <SignUpDialog
          open={status === SignStatus.SignUp}
          onCancel={() => setStatus(SignStatus.None)}
          onSuccess={(email, password) => {
            setEmail(email)
            setPassword(password)
            setStatus(SignStatus.VerifyEmail)
          }} />
        <VerifyEmailDialog
          email={email}
          password={password}
          open={status === SignStatus.VerifyEmail}
          onClose={() => setStatus(SignStatus.None)}
        />
        <ForgotPasswordDialog
          open={status === SignStatus.ForgotPassword}
          onCancel={() => setStatus(SignStatus.None)}
          onSuccess={(email) => {
            setEmail(email)
            setStatus(SignStatus.ResetPassword)
          }}
        />
        <ResetPasswordDialog
          email={email}
          open={status === SignStatus.ResetPassword}
          onCancel={() => setStatus(SignStatus.None)}
          onSuccess={() => { setStatus(SignStatus.None) }}
        />
      </Box >
    )}
  </UserContext.Consumer >
}

interface SignInProps {
  open: boolean,
  onCancel: () => void,
  onSuccess: () => void,
  toEmail: () => void
}

const SignInDialog: React.FC<SignInProps> = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')
  return <Dialog open={props.open} fullScreen={window.innerWidth < 600} style={{ zIndex: 1202 }}>
    {loading && <CircularProgress size={70} style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -35,
      marginLeft: -35,
      zIndex: 1300
    }} />}
    {error !== '' && <Snackbar
      open
      onClose={() => setError('')}
      autoHideDuration={3000}
      message={error} />}
    <DialogTitle>Kirjaudu</DialogTitle>
    <DialogContent>
      <Box>
        <Button
          style={{ marginBottom: '0.5rem' }}
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512" style={{ height: '1em', fill: 'white' }} >
            <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
          </svg>}
          onClick={() => {
            const params: FederatedSignInOptions = {
              provider: CognitoHostedUIIdentityProvider.Google
            }
            if (window.location.pathname !== '/') {
              params.customState = window.btoa(decodeURIComponent(encodeURIComponent(window.location.pathname)))
            }
            setLoading(true)
            Auth.federatedSignIn(params)
          }}
        >
          Google
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: '1em', fill: 'white' }} >
            <path d="M256 8C118.941 8 8 118.919 8 256c0 137.059 110.919 248 248 248 48.154 0 95.342-14.14 135.408-40.223 12.005-7.815 14.625-24.288 5.552-35.372l-10.177-12.433c-7.671-9.371-21.179-11.667-31.373-5.129C325.92 429.757 291.314 440 256 440c-101.458 0-184-82.542-184-184S154.542 72 256 72c100.139 0 184 57.619 184 160 0 38.786-21.093 79.742-58.17 83.693-17.349-.454-16.91-12.857-13.476-30.024l23.433-121.11C394.653 149.75 383.308 136 368.225 136h-44.981a13.518 13.518 0 0 0-13.432 11.993l-.01.092c-14.697-17.901-40.448-21.775-59.971-21.775-74.58 0-137.831 62.234-137.831 151.46 0 65.303 36.785 105.87 96 105.87 26.984 0 57.369-15.637 74.991-38.333 9.522 34.104 40.613 34.103 70.71 34.103C462.609 379.41 504 307.798 504 232 504 95.653 394.023 8 256 8zm-21.68 304.43c-22.249 0-36.07-15.623-36.07-40.771 0-44.993 30.779-72.729 58.63-72.729 22.292 0 35.601 15.241 35.601 40.77 0 45.061-33.875 72.73-58.161 72.73z" />
          </svg>}
          onClick={() => props.toEmail()}
        >
          Sähköposti
        </Button>
      </Box>
    </DialogContent>
    <DialogActions>

      <Button
        disabled={loading}
        color="primary"
        onClick={props.onCancel}
      >
        Peruuta
      </Button>
    </DialogActions>
  </Dialog >
}

interface SignInEmailProps {
  open: boolean,
  onCancel: () => void,
  onSuccess: () => void,
  toRegistration: () => void,
  toForgotPassword: () => void
}

const SignInEmailDialog: React.FC<SignInEmailProps> = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState('')
  return <Dialog open={props.open} fullScreen={window.innerWidth < 600} style={{ zIndex: 1202 }}>
    {loading && <CircularProgress size={70} style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -35,
      marginLeft: -35,
      zIndex: 1300
    }} />}
    {error !== '' && <Snackbar
      open
      onClose={() => setError('')}
      autoHideDuration={3000}
      message={error} />}
    <DialogTitle>Kirjaudu sähköpostiosoitteella</DialogTitle>
    <DialogContent>
      <Box>
        <TextField
          disabled={loading}
          autoFocus
          margin="dense"
          id="name"
          label="Sähköpostiosoite"
          type="email"
          fullWidth
          onChange={e => { setEmail(e.currentTarget.value) }}
        />
        <TextField
          disabled={loading}
          margin="dense"
          id="password"
          label="Salasana"
          type="password"
          fullWidth
          onChange={e => { setPassword(e.currentTarget.value) }}
        />
      </Box>
      <DialogContentText style={{ paddingTop: '1rem' }}>
        Ei vielä tunnusta:
        <Button
          onClick={e => props.toRegistration()}
        >
          Rekisteröidy
        </Button>.
      </DialogContentText>
      <DialogContentText>
        Salasana unohtunut:
        <Button
          onClick={e => props.toForgotPassword()}
        >
          Tilaa palautuskoodi
        </Button>.
      </DialogContentText>
    </DialogContent>
    <DialogActions>

      <Button
        disabled={loading}
        color="primary"
        onClick={props.onCancel}
      >
        Peruuta
      </Button>
      <Button
        disabled={loading}
        color="primary"
        onClick={() => {
          setLoading(true)
          Auth.signIn({ username: email, password }).then(r => {
            setLoading(false)
            props.onSuccess()
          }).catch(err => {
            setLoading(false)
            setError(`Kirjautuminen epäonnistui: ${I18n.get(err.message)}`)
          })
        }}
      >
        Kirjaudu
      </Button>
    </DialogActions>
  </Dialog >
}

interface ResetPasswordProps {
  open: boolean
  email: string
  onCancel: () => void
  onSuccess: () => void
}

const ResetPasswordDialog: React.FC<ResetPasswordProps> = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [code, setCode] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState('')
  return <Dialog open={props.open} fullScreen={window.innerWidth < 600} style={{ zIndex: 1202 }}>
    {loading && <CircularProgress size={70} style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -35,
      marginLeft: -35,
      zIndex: 1300
    }} />}
    {error !== '' && <Snackbar
      open
      onClose={() => setError('')}
      autoHideDuration={3000}
      message={error} />}
    <DialogTitle>Vaihda salasana</DialogTitle>
    <DialogContent>
      <Box>

        <DialogContentText>
          Alla olevaan sähköpostiin on lähetetty varmistuskoodi, joka tarvitaan salasanan palauttamiseksi.
          Tarkista roskaposti, jos koodia ei heti tule.
        </DialogContentText>
        <TextField
          disabled
          margin="dense"
          id="name"
          label="Sähköpostiosoite"
          type="email"
          fullWidth
          value={props.email}
        />
        <TextField
          disabled={loading}
          autoFocus
          margin="dense"
          id="code"
          label="Varmistuskoodi"
          type="text"
          fullWidth
          onChange={e => { setCode(e.currentTarget.value) }}
        />
        <TextField
          disabled={loading}
          margin="dense"
          id="password"
          label="Salasana"
          type="password"
          fullWidth
          onChange={e => { setPassword(e.currentTarget.value) }}
        />
      </Box>
    </DialogContent>
    <DialogActions>

      <Button
        disabled={loading}
        color="primary"
        onClick={props.onCancel}
      >
        Peruuta
      </Button>
      <Button
        disabled={loading}
        color="primary"
        onClick={() => {
          setLoading(true)
          Auth.forgotPasswordSubmit(props.email, code, password).then(r => {
            Auth.signIn({ username: props.email, password }).then(r => {
              setLoading(false)
              props.onSuccess()
            }).catch(err => {
              setLoading(false)
              setError(`Kirjautuminen epäonnistui: ${I18n.get(err.message)}`)
            })
          }).catch(err => {
            setError(`Salasanan vaihtaminen epäonnistui: ${I18n.get(err.message)}`)
          })

        }}
      >
        Vaihda salasana
      </Button>
    </DialogActions>
  </Dialog >
}

interface ForgotPasswordProps {
  open: boolean,
  onCancel: () => void,
  onSuccess: (email: string) => void
}

const ForgotPasswordDialog: React.FC<ForgotPasswordProps> = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [error, setError] = React.useState('')
  return <Dialog open={props.open} fullScreen={window.innerWidth < 600} style={{ zIndex: 1202 }}>
    {loading && <CircularProgress size={70} style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -35,
      marginLeft: -35,
      zIndex: 1300
    }} />}
    {error !== '' && <Snackbar
      open
      onClose={() => setError('')}
      autoHideDuration={3000}
      message={error} />}
    <DialogTitle>Tilaa salasanan palautuskoodi</DialogTitle>
    <DialogContent>
      <Box>
        <TextField
          disabled={loading}
          autoFocus
          margin="dense"
          id="name"
          label="Sähköpostiosoite"
          type="email"
          fullWidth
          onChange={e => { setEmail(e.currentTarget.value) }}
        />
      </Box>
    </DialogContent>
    <DialogActions>

      <Button
        disabled={loading}
        color="primary"
        onClick={props.onCancel}
      >
        Peruuta
      </Button>
      <Button
        disabled={loading}
        color="primary"
        onClick={() => {
          setLoading(true)
          Auth.forgotPassword(email).then(r => {
            setLoading(false)
            props.onSuccess(email)
          }).catch(err => {
            setLoading(false)
            setEmail('Palautuskoodin tilaaminen epäonnistui')
          })
        }}
      >
        Tilaa koodi
      </Button>
    </DialogActions>
  </Dialog >
}

interface SignUpProps {
  open: boolean,
  onSuccess: (email: string, password: string) => void,
  onCancel: () => void
}

const SignUpDialog: React.FC<SignUpProps> = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [givenName, setGivenName] = React.useState('')
  const [familyName, setFamilyName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState('')
  return <Dialog open={props.open} fullScreen={window.innerWidth < 600} style={{ zIndex: 1202 }}>

    {loading && <CircularProgress size={70} style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -35,
      marginLeft: -35,
      zIndex: 1300
    }} />}
    {error !== '' && <Snackbar
      open
      onClose={() => setError('')}
      autoHideDuration={3000}
      message={error} />}
    <DialogTitle>Rekisteröidy</DialogTitle>
    <DialogContent>
      <TextField
        disabled={loading}
        autoFocus
        margin="dense"
        id="name"
        label="Sähköpostiosoite"
        type="email"
        fullWidth
        onChange={e => { setEmail(e.currentTarget.value) }}
      />
      <TextField
        disabled={loading}
        margin="dense"
        id="password"
        label="Salasana"
        type="password"
        fullWidth
        onChange={e => { setPassword(e.currentTarget.value) }}
      />
      <TextField
        disabled={loading}
        margin="dense"
        id="given_name"
        label="Etunimi"
        type="text"
        fullWidth
        onChange={e => { setGivenName(e.currentTarget.value) }}
      />
      <TextField
        disabled={loading}
        margin="dense"
        id="family_name"
        label="Sukunimi"
        type="text"
        fullWidth
        onChange={e => { setFamilyName(e.currentTarget.value) }}
      />
    </DialogContent>
    <DialogActions>

      <Button
        disabled={loading}
        color="primary"
        onClick={props.onCancel}
      >
        Peruuta
      </Button>
      <Button
        disabled={loading}
        color="primary"
        onClick={() => {
          setLoading(true)
          Auth.signUp({
            username: email,
            password,
            attributes: {
              email,
              family_name: familyName,
              given_name: givenName
            }
          }).then(r => {
            setLoading(false)
            props.onSuccess(email, password)
          }).catch(err => {
            setLoading(false)
            setError(`Rekisteröityminen epäonnistui: ${I18n.get(err.message)}`)
          })
        }}
      >
        Rekisteröidy
      </Button>
    </DialogActions>
  </Dialog>
}


interface VerifyEmailProps {
  open: boolean,
  email: string
  password: string
  onClose: () => void
}

const VerifyEmailDialog: React.FC<VerifyEmailProps> = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [code, setCode] = React.useState('')
  const [error, setError] = React.useState('')
  return <Dialog open={props.open} fullScreen={window.innerWidth < 600} style={{ zIndex: 1202 }}>

    {loading && <CircularProgress size={70} style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -35,
      marginLeft: -35,
      zIndex: 1300
    }} />}
    {error !== '' && <Snackbar
      open
      onClose={() => setError('')}
      autoHideDuration={3000}
      message={error} />}
    <DialogTitle>Vahvista sähköpostiosoite</DialogTitle>
    <DialogContent>
      <DialogContentText variant="body1">
        Tähän sähköpostiosoitteeseen on lähetetty varmistuskoodi.
        Syötä se 'Koodi' -kenttään osoittaaksesi että olet sähköpostiosoitteen
        haltija.
      </DialogContentText>
      <TextField
        disabled
        margin="dense"
        id="email"
        label="Sähköpostiosoite"
        type="email"
        fullWidth
        value={props.email}
      />
      <TextField
        autoFocus
        disabled={loading}
        margin="dense"
        id="code"
        label="Koodi"
        type="text"
        fullWidth
        onChange={e => { setCode(e.currentTarget.value) }}
      />
    </DialogContent>
    <DialogActions>

      <Button
        disabled={loading}
        color="primary"
        onClick={props.onClose}
      >
        Peruuta
      </Button>
      <Button
        disabled={loading}
        color="primary"
        onClick={() => {
          setLoading(true)
          Auth.confirmSignUp(props.email, code).then(() => {
            Auth.signIn({
              username: props.email,
              password: props.password
            }).then(r => {
              setLoading(false)
              props.onClose()
            }).catch(err => {
              setLoading(false)
              setError(`Kirjautuminen epäonnistui: ${I18n.get(err.message)}`)
            })
          }).catch((err) => {

            setLoading(false)
            setError(`Sähköpostin vahvistus epäonnistui: ${I18n.get(err.message)}`)
          })

        }}
      >
        Rekisteröidy
      </Button>
    </DialogActions>
  </Dialog>
}