import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import { getCalendarWithReservations } from '../graphql/customQueries'
import { API, graphqlOperation } from 'aws-amplify'
import { ReservationType, GetCalendarWithReservationsQuery } from '../API'
import Snackbar from '@material-ui/core/Snackbar';
import { RouteComponentProps } from 'react-router-dom'
import { Content } from './Content'
import { PutReservationMutationVariables } from '../API'
import ReservationDialog from './ReservationDialog';
import { WeekComponent, Week, Entry } from './Week'
import setISOWeek from 'date-fns/setISOWeek'
import { CalendarInfoContext, CalendarInfo, UserContext } from './Context'


type State = {
    calendar: GetCalendarWithReservationsQuery
    loading: boolean
    messageOpen: boolean
    message: string
    slug: string
    editReservation?: Partial<PutReservationMutationVariables>
    editReservationOwner?: string
    editKey?: string
    week: Week
}


class Calendar extends Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props)
        // @ts-ignore
        const year: number | undefined = props.match.params.year as number
        // @ts-ignore
        const weekOfYear: number | undefined = props.match.params.weekOfYear as number
        let weekDate = new Date()
        if (year && weekOfYear) {
            weekDate = new Date(year, 4, 4)
            weekDate = setISOWeek(weekDate, weekOfYear)

            // console.log(`CALENDAR CONSTRUCTOR: ${weekOfYear}/${year} -> ${weekDate}`)
        }
        const week = new Week(weekDate)
        this.state = {
            calendar: { getCalendar2: null },
            loading: false,
            message: "",
            messageOpen: false,
            // @ts-ignore
            slug: props.match.params.slug,
            week
        }
        // this.load(week)
        this.remove = this.remove.bind(this)
        this.load = this.load.bind(this)
        this.openMessage = this.openMessage.bind(this)
        this.closeMessage = this.closeMessage.bind(this)
    }

    componentDidMount() {
        this.load(this.state.week)
    }
    componentDidUpdate(prevProps: RouteComponentProps) {
        if (prevProps.match.url === this.props.match.url) return
        const props = this.props
        // @ts-ignore
        const year: number | undefined = props.match.params.year as number
        // @ts-ignore
        const weekOfYear: number | undefined = props.match.params.weekOfYear as number
        let weekDate = new Date()
        if (year && weekOfYear) {
            weekDate = new Date(year, 4, 4)
            weekDate = setISOWeek(weekDate, weekOfYear)
        }
        const week = new Week(weekDate)
        // this.setState({ week })
        this.load(week)
    }

    openMessage(message: string) {
        this.setState({ messageOpen: true, message })
    }
    closeMessage() {
        this.setState({ messageOpen: false })
    }
    remove(slugToRemove: string) {
        // if (this.state.loading) return
        // this.setState({ loading: true })
        // const p = API.graphql(graphqlOperation(removeCalendar, {slug:slugToRemove})) as Promise<any>
        // p.then(res => {
        //     const r = res.data as RemoveCalendarMutation
        //     this.openMessage("poisto onnistui")
        //     this.setState(({calendars}) => {
        //         return {
        //             calendars: calendars.filter(({slug}) => (slug !== r.removeCalendar?.slug)),
        //             loading: false
        //         }
        //     })
        // }).catch(err => {
        //     this.openMessage("poisto epäonnistui")
        //     this.setState({loading:false})
        // })
    }
    load(week: Week) {
        const { slug } = this.state
        const from = week.from.toISOString()
        const to = week.to.toISOString()
        if (this.state.loading) return
        this.setState({ loading: true })
        const p = API.graphql(graphqlOperation(getCalendarWithReservations, ({ slug, from, to }))) as Promise<any>
        p.then(res => {
            const r = res.data as GetCalendarWithReservationsQuery
            if (r.getCalendar2) {
                week.resetEntries()
                r.getCalendar2?.reservations?.forEach(r => {
                    week.addEntry({
                        id: r.id,
                        from: new Date(r.from),
                        to: new Date(r.to),
                        type2: r.type2,
                        description: r.description,
                        additionalInfo: r.additionalInfo || '',
                        owner: r.owner
                    })
                })
                this.setState({
                    calendar: r,
                    loading: false,
                    week
                })
                this.forceUpdate()
            } else {
                this.openMessage('kalenteria ei löytynyt')
                this.setState({ loading: false })
            }
        }).catch(err => {
            this.setState({ loading: false })
        })
    }

    render() {
        const {
            loading,
            calendar,
            messageOpen,
            message,
            editReservation,
            editKey,
            week,
            slug,
            editReservationOwner
        } = this.state
        let title = '...'
        let description = ''
        const reservationTypes = calendar.getCalendar2?.reservationTypes

        if (calendar.getCalendar2) {
            title = calendar.getCalendar2.name
            description = calendar.getCalendar2.description || ''
        }
        return (
            <Content>
                <UserContext.Consumer>{user => (
                    <CalendarInfoContext.Provider value={new CalendarInfo(calendar, user)}>
                        {loading && <CircularProgress size={70} style={{
                            position: 'fixed',
                            top: '50%',
                            left: '50%',
                            marginTop: -35,
                            marginLeft: -35,
                            zIndex: 1300
                        }} />}
                        <Box>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h5" component="h3">{title}</Typography>
                            </Box>
                            <Box paddingBottom={2}>
                                <Typography variant="body1">
                                    {description}
                                </Typography>
                            </Box>
                            {reservationTypes && reservationTypes.length > 0 && (
                                <Paper variant="outlined" style={{ padding: '0.5rem', display: 'flex', justifyContent: 'space-evenly' }}>
                                    {reservationTypes.map(t => (
                                        <Box
                                            key={`t-${t.id}`}
                                            borderRadius={3}
                                            style={{
                                                backgroundColor: t.color,
                                                padding: '0.3rem'
                                            }}
                                        >
                                            {t.name}
                                        </Box>
                                    ))}
                                </Paper>
                            )}
                            {calendar.getCalendar2 &&
                                <WeekComponent
                                    week={week}
                                    dayStart={calendar.getCalendar2!.dayStart}
                                    dayEnd={calendar.getCalendar2!.dayEnd}
                                    // setWeek={d => {
                                    //     this.setState({ week: new Week(d) })
                                    //     this.load()
                                    // }}
                                    basePath={`/c/${slug}`}
                                    key={week.from.toISOString()}
                                    add={d => {
                                        this.setState({
                                            editReservation: {
                                                id: undefined,
                                                to: undefined,
                                                description: undefined,
                                                calendarSlug: calendar.getCalendar2?.slug,
                                                type: ReservationType.reservation,
                                                type2: !!reservationTypes && reservationTypes.length > 0 ? reservationTypes[0].id : undefined,
                                                from: d.toISOString()
                                            },
                                            editKey: `new-reservation-${new Date().toISOString()}`
                                        })

                                    }}
                                    edit={(e: Entry) => {
                                        this.setState({
                                            editReservation: {
                                                calendarSlug: calendar.getCalendar2!.slug,
                                                type: ReservationType.reservation,
                                                from: e.from.toISOString(),
                                                to: e.to.toISOString(),
                                                type2: e.type2,
                                                description: e.description,
                                                additionalInfo: e.additionalInfo,
                                                id: e.id,
                                            },
                                            editReservationOwner: e.owner,
                                            editKey: e.id
                                        })
                                    }}
                                />
                            }

                        </Box>
                        <Snackbar
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center"
                            }}
                            open={messageOpen}
                            autoHideDuration={6000}
                            onClose={this.closeMessage}
                            message={<span id="message-id">{message}</span>}

                        />
                        {editReservation && <ReservationDialog
                            onClose={() => {
                                this.setState({ editReservation: undefined, editReservationOwner: undefined })
                                this.load(this.state.week)
                            }}
                            initial={editReservation}
                            owner={editReservationOwner}
                            key={editKey} />}
                    </CalendarInfoContext.Provider>
                )}</UserContext.Consumer>
            </Content>
        )
    }
}

export default Calendar