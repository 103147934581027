/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const myMemberships = /* GraphQL */ `
  query MyMemberships {
    myMemberships {
      member
      calendarSlug
      nick
      email
      isUser
      isAdmin
    }
  }
`;
export const myCalendars = /* GraphQL */ `
  query MyCalendars {
    myCalendars {
      slug
      name
      description
      owner
      dayStart
      dayEnd
    }
  }
`;
export const getCalendar = /* GraphQL */ `
  query GetCalendar($slug: String!) {
    getCalendar(slug: $slug) {
      slug
      name
      description
      owner
      dayStart
      dayEnd
    }
  }
`;
export const getCalendar2 = /* GraphQL */ `
  query GetCalendar2($slug: String!) {
    getCalendar2(slug: $slug) {
      slug
      name
      description
      owner
      dayStart
      dayEnd
    }
  }
`;
