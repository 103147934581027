import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import { myCalendars } from '../graphql/queries'
import { myMembershipCalendars } from '../graphql/customQueries'
import { removeCalendar } from '../graphql/mutations'
import { API, graphqlOperation } from 'aws-amplify'
import { MyCalendarsQuery, RemoveCalendarMutation, MyMembershipCalendarsQuery } from '../API'
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar';
import { styled } from '@material-ui/core/styles';
import NewCalendarDialog from './NewCalendarDialog';
import { Content } from './Content'
import { RouteComponentProps } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const IButton = styled(IconButton)(({ theme }) => ({
    padding: theme.spacing(1),
}))

type Calendar = {
    __typename: "Calendar";
    slug: string;
    name: string;
    description?: string | null | undefined
    owner: string;
    dayStart: number
    dayEnd: number
}

type State = {
    loading: boolean
    loadingShared: boolean
    calendars: Calendar[]
    // memberships: InstanceType<typeof mssample.myMemberships>
    memberships: MyMembershipCalendarsQuery
    editCalendar?: {
        slug?: string
        name: string
        description?: string | null | undefined
        dayStart: number
        dayEnd: number
    }
    slugToRemove?: string
    messageOpen: boolean
    message: string
}

class MyCalendars extends Component<RouteComponentProps, State> {
    state: State = {
        loading: false,
        loadingShared: false,
        calendars: [],
        memberships: { myMemberships: null },
        message: "",
        messageOpen: false
    }
    constructor(props: RouteComponentProps) {
        super(props)
        this.remove = this.remove.bind(this)
        this.load = this.load.bind(this)
        this.loadShared = this.loadShared.bind(this)
        this.openMessage = this.openMessage.bind(this)
        this.closeMessage = this.closeMessage.bind(this)
    }
    componentDidMount() {
        this.load()
        this.loadShared()
    }
    openMessage(message: string) {
        this.setState({ messageOpen: true, message })
    }
    closeMessage() {
        this.setState({ messageOpen: false })
    }
    remove(slugToRemove: string) {
        if (this.state.loading) return
        this.setState({ loading: true })
        const p = API.graphql(graphqlOperation(removeCalendar, { slug: slugToRemove })) as Promise<any>
        p.then(res => {
            const r = res.data as RemoveCalendarMutation
            this.openMessage("poisto onnistui")
            this.setState(({ calendars }) => {
                return {
                    calendars: calendars.filter(({ slug }) => (slug !== r.removeCalendar?.slug)),
                    loading: false,
                    slugToRemove: undefined
                }
            })
        }).catch(err => {
            this.openMessage("poisto epäonnistui")
            this.setState({ loading: false })
        })
    }
    load() {
        if (this.state.loading) return
        this.setState({ loading: true, editCalendar: undefined })
        const p = API.graphql(graphqlOperation(myCalendars)) as Promise<any>
        p.then(res => {
            const r = res.data as MyCalendarsQuery
            if (r.myCalendars) {
                this.setState({
                    calendars: r.myCalendars,
                    loading: false
                })
            } else {
                this.setState({ loading: false })
            }
        }).catch(err => {
            this.setState({ loading: false })
        })
    }
    loadShared() {
        if (this.state.loadingShared) return
        this.setState({ loadingShared: true })
        const p = API.graphql(graphqlOperation(myMembershipCalendars)) as Promise<any>
        p.then(res => {
            const r = res.data as MyMembershipCalendarsQuery
            if (r.myMemberships) {
                this.setState({
                    memberships: r,
                    loading: false
                })
            } else {
                this.setState({ loading: false })
            }
        }).catch(err => {
            this.setState({ loading: false })
        })
    }

    render() {
        const { loading, calendars, editCalendar, messageOpen, message, memberships, slugToRemove } = this.state
        return (
            <Content>
                {loading && <LinearProgress />}
                <Box>
                    {calendars && calendars.length > 0 && (

                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="h5" component="h3">Omat kalenterit</Typography>
                            <Fab color="primary" onClick={() => {
                                this.setState({
                                    editCalendar: {
                                        name: '',
                                        description: '',
                                        slug: undefined,
                                        dayStart: 0,
                                        dayEnd: 24
                                    }
                                })
                            }}><AddIcon /></Fab>
                        </Box>
                    )}

                    {(!calendars || calendars.length === 0) && (

                        <Box >
                            <Typography variant="h5">Sinulla ei ole vielä kalentereita</Typography>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        editCalendar: {
                                            name: '',
                                            description: '',
                                            slug: undefined,
                                            dayStart: 0,
                                            dayEnd: 24
                                        }
                                    })
                                }
                                }
                            >
                                Luo ensimmäinen
                        </Button>
                        </Box>
                    )}

                    {calendars?.map(calendar => (
                        <div key={calendar.slug}
                            className="hovergray"
                            style={{
                                flexGrow: 1,
                                verticalAlign: 'middle',
                                borderBottom: '1px solid #eee',
                                borderTop: '1px solid #eee',
                                borderLeftColor: '#41c300',
                                borderLeftWidth: '0.5rem',
                                borderLeftStyle: 'solid',
                                padding: '2px',
                                marginBottom: '0.5rem',
                                paddingLeft: '0.7rem',
                                cursor: 'pointer',
                            }}
                            onClick={() => this.props.history.push(`/c/${calendar.slug}`)}
                        >
                            <Grid container spacing={2} style={{}}>
                                <Grid xs={12} sm={12} md={4} lg={3} item>
                                    <div style={{ fontWeight: 'inherit', marginBottom: '2px', fontSize:'125%' }}>{calendar.name}</div>
                                    <div style={{ cursor: 'text' }}>
                                        <Typography variant="caption">{`https://varaus.net/c/${calendar.slug}`}</Typography>
                                    </div>
                                </Grid>
                                <Grid xs={12} sm={10} md={6} lg={8} item>{calendar.description}</Grid>
                                <Grid xs={12} sm={2} md={2} lg={1} item style={{ textAlign: "right", verticalAlign: 'bottom' }}>
                                    <IButton onClick={(e) => {
                                        e.stopPropagation()
                                        this.setState({
                                            editCalendar: {
                                                name: calendar.name,
                                                description: calendar.description,
                                                slug: calendar.slug,
                                                dayStart: calendar.dayStart,
                                                dayEnd: calendar.dayEnd
                                            }
                                        })
                                    }} aria-label="edit">
                                        <EditIcon color="primary" fontSize="small" />
                                    </IButton>
                                    <IButton
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            this.setState({ slugToRemove: calendar.slug })
                                            // this.remove(calendar.slug)
                                        }} aria-label="delete">
                                        <DeleteIcon color="primary" fontSize="small" />
                                    </IButton>
                                </Grid>
                            </Grid>
                        </div>
                    ))}



                    <Dialog
                        open={!!slugToRemove}
                        onClose={() => this.setState({ slugToRemove: undefined })}
                    >
                        <DialogTitle id="alert-dialog-title">{"Haluatko varmasti poistaa kalenterin?"}</DialogTitle>
                        <DialogContent>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ slugToRemove: undefined })} color="primary">
                                En
                            </Button>
                            <Button onClick={() => {
                                this.remove(slugToRemove!)
                            }} color="primary" autoFocus>
                                Kyllä
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Box pt="2rem" pb="1rem">
                        <Typography variant="h5" component="h3">Kanssasi jaetut kalenterit</Typography>
                    </Box>

                    {memberships.myMemberships?.map((membership, i) => (
                        <div key={i}
                            className="hovergray"
                            style={{
                                flexGrow: 1,
                                verticalAlign: 'middle',
                                borderBottom: '1px solid #eee',
                                borderTop: '1px solid #eee',
                                borderLeftColor: '#41c300',
                                borderLeftWidth: '0.5rem',
                                borderLeftStyle: 'solid',
                                padding: '2px',
                                marginBottom: '0.5rem',
                                paddingLeft: '0.7rem',
                                cursor: 'pointer',
                            }}
                            onClick={() => this.props.history.push(`/c/${membership.calendar!.slug}`)}
                        >
                            <Grid container spacing={2} style={{}}>
                                <Grid xs={12} sm={12} md={4} lg={3} item>
                                    <div style={{ fontWeight: 'bold', marginBottom: '2px' }}>{membership.calendar!.name}</div>
                                    <div style={{ cursor: 'text' }}>
                                        <Typography variant="caption">{`https://varaus.net/c/${membership.calendar!.slug}`}</Typography>
                                    </div>
                                </Grid>
                                <Grid xs={12} md={8} lg={9} item>{membership.calendar!.description}</Grid>
                            </Grid>
                        </div>
                    ))}

                    <Box pt="2rem">
                        <Typography variant="h5">Korjauksia / muutoksia</Typography>

                        <Typography variant="subtitle1">30.12.2020</Typography>
                        <ul>
                            <li>Korjattu viikon vaihtamisessa ollut virhe, joka esti kalenterin käytön viikosta 1/2021 alkaen.</li>
                        </ul>
                        <Typography variant="subtitle1">30.1.2020</Typography>
                        <ul>
                            <li>Näytä omat tiedot linkki ainoastaan kirjautuneille käyttäjille</li>
                            <li>Avatar näkyviin kaikille, vaikka ei olisikaan profiilikuvaa</li>
                            <li>Uloskirjautuminen ja omat tiedot avatarin taakse menuun</li>
                        </ul>
                        <Typography variant="subtitle1">27.1.2020</Typography>
                        <ul>
                            <li>Etusivun muotoilu</li>
                            <li>Poistettu virheilmoitus 'Date should not be before minimal date' merkinnöistä joiden alkuaika on ennen nykyhetkeä</li>
                            <li>Lisätty mahdollisuus lisätä merkintöjä 'historiaan'</li>
                        </ul>
                        <Typography variant="subtitle1">26.1.2020</Typography>
                        <ul>
                            <li>Kalenterimerkinnän muutosoikeus säilyy merkinnän alkuperäisellä tekijällä vaikka kalenterin ylläpitäjä/omistaja muokkaa merkintää</li>
                        </ul>
                    </Box>

                </Box >
                {loading && <LinearProgress />
                }
                {
                    editCalendar &&
                    <NewCalendarDialog
                        onClose={(changed: boolean) => {
                            if (changed) {
                                this.openMessage("tallennus onnistui")
                                this.load()
                            } else {
                                this.setState({ editCalendar: undefined })
                            }

                        }}
                        name={editCalendar.name}
                        description={editCalendar.description}
                        slug={editCalendar.slug}
                        dayStart={editCalendar.dayStart}
                        dayEnd={editCalendar.dayEnd}
                    />
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    open={messageOpen}
                    autoHideDuration={6000}
                    onClose={this.closeMessage}
                    message={<span id="message-id">{message}</span>}

                />
            </Content >
        )
    }
}

export default MyCalendars