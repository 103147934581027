import React from 'react';
import { RouteComponentProps } from 'react-router-dom'
import { Content } from './Content'
import { GetCalendarWithMembersQuery } from '../API'
import { getCalendarWithMembers } from '../graphql/customQueries'
import { API, graphqlOperation } from 'aws-amplify'
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
const sample = { getCalendar2: null } as GetCalendarWithMembersQuery

type State = Partial<NonNullable<typeof sample.getCalendar2>>

export class EditCalendar extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {
        // @ts-ignore
        const p = API.graphql(graphqlOperation(getCalendarWithMembers, { slug: this.props.match.params.slug })) as Promise<any>
        p.then(v => {
            if (v.data.getCalendar2) {
                this.setState(v.data.getCalendar2 as State)
            }
        }).catch(err => {
            console.log('load:', err)
        })
    }
    nameError(name: string | undefined): string | undefined {
        if (!name || name.length === 0) return 'pakollinen tieto'
    }
    render() {
        const name = this.state?.name || ''
        const slug = this.state?.slug || ''
        const description = this.state?.description || ''
        const dayStart = this.state?.dayStart
        const dayEnd = this.state?.dayEnd
        const reservationTypes = this.state?.reservationTypes
        const members = this.state?.members
        const daySliderValue = [
            dayStart ? dayStart : 0,
            dayEnd || 24
        ]
        return (
            <Content>
                <Typography variant="h3">Kalenterin asetukset</Typography>
                <Paper
                    elevation={4}
                    style={{
                        padding: '1rem',
                        marginTop: '1rem',
                    }}
                >
                    <Typography variant="h5" style={{ marginBottom: '1rem' }}>Perustiedot</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} >
                            <TextField
                                value={name}
                                inputProps={{
                                    readOnly: true,
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                fullWidth
                                label="Nimi" />
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <TextField
                                value={slug}
                                inputProps={{
                                    readOnly: true,
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                fullWidth
                                label="Tunniste" />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                value={`${daySliderValue[0]}:00 - ${daySliderValue[1]}:00`}
                                inputProps={{
                                    readOnly: true,
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                fullWidth
                                label="Varattavissa oleva aika" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={description}
                                inputProps={{
                                    readOnly: true,
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                multiline
                                fullWidth
                                label="Kuvaus" />
                        </Grid>


                    </Grid>
                </Paper>
                <Paper
                    elevation={4}
                    style={{
                        padding: '1rem',
                        marginTop: '1rem',
                    }}
                >
                    <Typography variant="h5" style={{ marginBottom: '1rem' }}>Varaustyypit</Typography>
                    <Typography variant="body2" style={{ marginBottom: '1rem' }}>
                        Varaustyypeillä voidaan määrittää varausten värit.
                        Tyypit järjestetään lomakkeilla tunnisteen mukaan aakkosjärjestykseen.
                    </Typography>

                    {reservationTypes && reservationTypes.map(t => (
                        <Grid
                            key={t.id}
                            container
                            spacing={2}
                            style={{
                                backgroundColor: t.color,
                                marginBottom: 8,
                            }}
                        >

                            <Grid item xs={4} sm={2} >
                                <TextField
                                    value={t.id}
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    fullWidth
                                    label="Tunniste" />
                            </Grid>
                            <Grid item xs={8} sm={4} >
                                <TextField
                                    value={t.name}
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    fullWidth
                                    label="Nimi" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    value={t.description}
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    fullWidth
                                    label="Selite" />
                            </Grid>
                        </Grid>
                    ))}
                </Paper>
                <Paper
                    elevation={4}
                    style={{
                        padding: '1rem',
                        marginTop: '1rem',
                    }}
                >
                    <Typography variant="h5" style={{ marginBottom: '1rem' }}>Jäsenet</Typography>

                    {members && members.map((m, i) => (
                        <Grid
                            key={`member-${i}`}
                            container
                            style={{
                                borderTop: '1px solid #eee'
                            }}
                            className="hovergray"
                        >

                            <Grid item xs={8} sm={4}>
                                {m.nick || <span style={{color:'#aaa'}}>nimi puuttuu</span>}
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                {m.isAdmin ? 'Ylläpitäjä' : (m.isUser ? 'Käyttäjä' : 'Katselija')}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {m.email || <span style={{color:'#aaa'}}>sähköposti puuttuu</span>}
                            </Grid>
                        </Grid>
                    ))}
                </Paper>
            </Content>
        )
    }
}
